/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d9311e17-1edc-44a0-af77-4f469626c443",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_b7ZjuHtnz",
    "aws_user_pools_web_client_id": "5d7m8qmpgqes7qtff4mpgklc4r",
    "oauth": {}
};


export default awsmobile;
